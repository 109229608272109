<template>
	<div>
		<div>
			<div class="head">{{price}}</div>
			<div class="head-placeholder bg-white"></div>
			<div class="btn-wrap">
				<van-button type="info" class @click="toPay" block>确定支付</van-button>
			</div>
		</div>
		
		<van-radio-group v-model="pay_type">
		   <van-cell-group class="from-group-item">
		    <van-cell title="微信支付" clickable @click="pay_type='1'" :icon="wechat_img">
		       <van-radio slot="right-icon" name="1" />
		    </van-cell>
		  </van-cell-group>
		</van-radio-group>
	</div>
</template>

<script>
	import {
		Button,
		RadioGroup, 
		Radio,
		Cell,
		CellGroup
	} from 'vant';
	import {
		get_ticket_detail,
		tickets_buildorder,
		live_order_pay,
		user_marketing_hot_orderdetail,
		user_marketing_hot_buildorder,
		groupbuy_user_orderdetail,
		groupbuy_user_buildorder
	} from "@/request/api";
	import {
		mapState
	} from 'vuex';
	import wx from "@/request/utils/wechat"; //微信JSSDK
	import cache from '@/request/utils/cache';

	export default {
		components: {
			[Button.name]: Button,
			[Radio.name]:Radio,
			[RadioGroup.name]:RadioGroup,
			[Cell.name]: Cell,
			[CellGroup.name]: CellGroup
		},
		data() {
			return {
				id: 0,
				type: '',
				price: 0.00,
				back: 3,
				ticketInfo: {},
				info: {},
				pay_type:'1',
				wechat_img:require('@/assets/images/wechat.png')
			}
		},
		computed: {},
		mounted: function() {
			this.id = this.$route.query.id;
			this.type = this.$route.query.type;
			let _this = this;
			
			if (!this.id || !this.type){
				this.$dialog({
					message: "参数有误"
				});
				return;
			}
			
			if (this.type == 'ticket'){
				get_ticket_detail({
					id: this.id
				})
				.then(res => {
					if (res.code == 1) {
						_this.price = res.data.card_info.amount
						_this.ticketInfo = res.data
					} else {
						this.$dialog({
							message: res.msg
						});
					}
				}).catch(err => {
					this.$dialog({
						message: "请求有误,请重新刷新页面"
					});
				});
			}else if(this.type == 'hot'){
				user_marketing_hot_orderdetail({rid: this.id}).then(res => {
				    if (res.code == 1) {
						this.price = res.data.deposit_price
				        this.info = res.data;
				    } else {
				        this.$dialog({title: '提示', message: res.msg}).then(g => {
				            this.$router.go(-1);
				        });
				    }
				}).catch(error => {
				    this.$dialog({title: '提示', message: "访问出错"}).then(g => {
				        this.$router.go(-1);
				    });
				});
			}else if(this.type == 'group'){
				groupbuy_user_orderdetail({order_id: this.id}).then(res => {
				    if (res.code == 1) {
						this.price = res.data.final_price
				        this.info = res.data;
				    } else {
				        this.$dialog({title: '提示', message: res.msg}).then(g => {
				            this.$router.go(-1);
				        });
				    }
				}).catch(error => {
				    this.$dialog({title: '提示', message: "访问出错"}).then(g => {
				        this.$router.go(-1);
				    });
				});
			}
			
		},
		methods: {
			toPay() {
				if (!this.id || !this.type){
					this.$dialog({
						message: "参数有误"
					});
					return;
				}
				this.payTicket()
			},
			payTicket() {
				let _this = this
				if (this.type == 'ticket'){
					tickets_buildorder({
						id: this.$route.query.id
					}).then(res => {
						let payinfo = res.data.pay_info;
						live_order_pay({
							rid: payinfo.id,
							type: "wechat"
						}).then(e => {
							if (e.code == 1) {
								wx.pay(e.data,res => {
									_this.$router.push({
										path: "/home",
										query: {}
									})
								});
							} else {
								_this.$toast.fail(e.msg);
							}
						})
					})
				}else if (this.type == 'hot'){
					user_marketing_hot_buildorder({
						id: this.id
					}).then(res => {
						let payinfo = res.data.pay_info;
						live_order_pay({
							rid: payinfo.id,
							type: "wechat"
						}).then(e => {
							if (e.code == 1) {
								wx.pay(e.data,res => {
									_this.$router.push({
										path: "/home",
										query: {}
									})
								});
							} else {
								_this.$toast.fail(e.msg);
							}
						})
					})
				}else if (this.type == 'group'){
					groupbuy_user_buildorder({
						id: this.id
					}).then(res => {
						let payinfo = res.data.pay_info;
						live_order_pay({
							rid: payinfo.id,
							type: "wechat"
						}).then(e => {
							if (e.code == 1) {
								wx.pay(e.data,res => {
									_this.$router.push({
										path: "/home",
										query: {}
									})
								});
							} else {
								_this.$toast.fail(e.msg);
							}
						})
					})
				}
			},
			isIos: function () {
			    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
			        return true;
			    }
			    return false;
			}
		}
	};
</script>

<style lang="less">
	.head{
		text-align: center;
		padding: 50px;
		font-size: 34px;
		
		&::before{
			content: '￥';
			font-size: 22px;
		}
	}
	.btn-wrap {
		width: 100%;
		bottom: 40px;
		position: fixed;
		
		button{
			width: 65%;
			margin: 0 auto;
			border: none;
			border-radius: 25px;
			background: #37dc67;
		}
	}
	
	.van-icon-success{
		border-color: #37dc67 !important;
		background: #37dc67 !important;
	}
</style>
